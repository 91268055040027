import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Button } from "@material-ui/core";

const webStyles = {
  container: {
    background: '#F8F9FA',
    height: '100vh',
    overflow: 'auto',
  },
  mainContainer: {
    padding: '16px 0 0',
    background:"#fff"
  },
  centerAlign: {
    alignItems: 'center'
  },
  rightAlign: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
};
import SideNav from "../../../components/src/SideNav";
import TopNavBar from "../../../components/src/TopNavBar";
import { emptySvg } from "../../leadmanagement/src/assets";
import { checkMark } from "./assets";
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      // 20px 40px 34px 44px
      <Box style={webStyles.container}>
        <Grid container>
          <Grid item xs={3} md={2} className="sidebar-wrapper">
            <SideNav {...this.props.navigation} />
          </Grid>
          <Grid item xs={9} md={10} className="main-content-wrapper" style={{padding:"0px"}}>
            <Grid>
              <div style={{padding:"20px 40px 0px 44px",background:"#fff"}}>

              <TopNavBar currentPage={{ name: 'Training courses', path: 'training' }} />
                <Box className="dashboard-nav-container">
                  <Box className="dashboard-heading">
                    <Box>
                      <Typography className="asset-heading-main">
                        Training courses
                      </Typography>
                      <Typography className="asset-heading-sub">
                        Learn all about Builder.ai and its products and get certified.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
              <Box style={webStyles.mainContainer}>
                <Box>
                  <iframe
                  src={this.state.buUri} 
                  title="Builder University"
                  style={{height:"100vh",width:"100%",border:"none"}}
                  id="builderUniversity"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid >
      </Box >
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
