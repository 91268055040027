import React, { useEffect, useState } from 'react';
import { Doughnut } from "react-chartjs-2";

export default function ClosedDealsRevenueGraph(props: any) {
    const [pieChartData, setPieChartData] = useState<any>({
        labels: [],
        datasets: [
            {
                label: "",
                data: [],
                backgroundColor: [],
                borderColor: "#ffffff",
                borderWidth: 0.7,
                revenue: [],
            }
        ]
    });

    useEffect(() => {
        if (props.data?.length) {
            const pieChartData = {
                labels: props.data.map((deal: any) => deal.company_name),
                datasets: [
                    {
                        label: "",
                        data: props.data.map((deal: any) => deal.closed_deals_count),
                        backgroundColor: props.data.map((deal: any) => deal.color),
                        borderColor: "#ffffff",
                        borderWidth: 0.7,
                        revenue: props.data.map((deal: any) => deal.revenue),
                    }
                ]
            };
            setPieChartData(pieChartData);
        }
    }, [props.data]);

    const externalTooltipHandler = (context: any) => {
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }


        if (tooltip.body) {
            const element = tooltipEl.querySelector('div');

            const closedDeals = tooltip.dataPoints[0].formattedValue;
            const revenue = tooltip.dataPoints[0].dataset.revenue[tooltip.dataPoints[0].dataIndex];
            element.innerHTML = `
                <img 
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABnSURBVHgBfc/NCYAwDIbhL2YPvTiGiGeX0tEcxIsO4k8Epabpl0NJeR8KBch0/TgJi8A5C4u2C4sZ8PEHoviBUnwAizYVcBS/aqP7ti51095IhhDYwZC+SwlpeomQ+ic9yoBHIUjRBesEMXy4I40/AAAAAElFTkSuQmCC" 
                    alt="Base64 Image"
                    style="
                        position: absolute;
                        left: -10px;
                        top: 12px;
                        "
                >
                <div style="font-size:12px;"><strong>Closed deals:</strong> ${closedDeals}</div>
                <div style="font-size:12px;"><strong>Revenue:</strong> ${revenue}</div>
            `;
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = (positionX + 60) + tooltip.caretX + 'px';
        tooltipEl.style.top = (positionY - 40) + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';


    }

    const getOrCreateTooltip = (chart: any) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = '#3C3E49';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const element = document.createElement('div');
            element.style.margin = '0px';
            element.style.width = "max-content"
            element.style.padding = "6px"
            element.style.position = "relative"

            tooltipEl.appendChild(element);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    return (
        <Doughnut
            options={{
                cutout: 83,
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    },
                    legend: { display: false },
                },
            }}
            data={pieChartData}
        />
    );
}
